import React, { useState } from 'react';
import languageContext from "./languageContext"

const LanguageState = (props) => {
    const [lang,setLang]=useState(0);
    const [sessionTimeout,setSessionTimeout]=useState(false);
    const [phone,setPhone]=useState('');
    const [chats,setChats]=useState([])
    const [dataApi, setData] = useState([]);
    return (
        <languageContext.Provider value={{lang,setLang,sessionTimeout,setSessionTimeout,phone,setPhone,chats,setChats,dataApi,setData}}>
            {props.children}
        </languageContext.Provider>
    );
};

export default LanguageState;
