import { useNavigate } from "react-router-dom"
import { useState } from "react";

const HistoryComp=(props)=>{
    const navigate = useNavigate();
    const handleNavigation=(id)=>{
        navigate(`/ask/${id}`)
    }
    const [isExpanded, setIsExpanded] = useState(false);

    const handleToggle=()=>{
        setIsExpanded(prevState => !prevState);
    }

    return(
        <div className="bg-slate-200 w-full mx-2 my-2 rounded-md px-2 py-1" onClick={()=>handleNavigation(props.id)}>
            <div className=" font-bold">{isExpanded? props.heading: props.heading.length>200 ? `${ props.heading.slice(0, 200)}...`:props.heading}
            {props.heading.length > 200 && (
        <button className='bg-bluebtn rounded-lg px-8 py-2 my-4 text-white font-[100] w-full text-center cursor-pointer'  onClick={handleToggle}>
          {isExpanded ? 'Read Less' : 'Read More'}
          </button>
      )}
            </div>
            <div>{isExpanded ? props.desc : props.desc.length>300 ? `${props.desc.slice(0, 300)}...`:props.desc}
            {props.desc.length > 300 && (
        <button className='bg-bluebtn rounded-lg px-8 py-2 my-4 text-white font-[100] w-full text-center cursor-pointer' onClick={handleToggle}>
          {isExpanded ? 'Read Less' : 'Read More'}
        </button>
      )}
            </div>
        </div>
    )
}
export default HistoryComp