import HistoryComp from "./History";
import languageContext from "../context/language/languageContext";
import { useContext, useEffect } from "react";
import axios from 'axios';
import { version } from "react-dom";

const PrevQues = () => {
    // Accessing chats from languageContext
    const { chats, setChats, setData, dataApi} = useContext(languageContext);
    const { lang,setSessionTimeout } = useContext(languageContext);
    useEffect(() => {  
        gettingResponse()
    }, [dataApi]);



    const gettingResponse = async () => {
        try {
            let headersList = {
                "Accept": "*/*",
                "Authorization": `Bearer ${localStorage.getItem('token')}`,
                "Content-Type": "application/json"
            };
            let response = await axios.post(
                "https://www.careplus.gopillz.com/chat_history",
                null,
                { headers: headersList }
            );
            // console.log(response.data.chat_history)
            setChats(response.data.chat_history)
        } catch (error) {
            if (error.response && error.response.data.detail === 'Token has expired') {
                setSessionTimeout(true)
            } else {
                console.error("Error Details:", error.message);
                alert('Please try again later, something went wrong');
            }
        }
    }

    return (
        <div className="relative flex justify-between items-stretch h-full">
            <div className="w-11/12 relative py-1">
                {chats && chats.map((chat, index) => (
                    <HistoryComp
                        heading={chat.user_question} 
                        desc={chat.bot_response}
                    />
                ))}
                

            
            </div>
        </div>
    );
}

export default PrevQues;
